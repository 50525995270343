<template>
  <div>
    <!-- Óbito -->
    <section v-if="opcaoTipoAntecipacao == 'Falecido'">
      <b-row>
        <b-col md="4">
          <b-form-group label="Valor da Renegociação" label-for="valor">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">R$</span>
              </b-input-group-prepend>
              <b-form-input
                :disabled="true"
                style="padding-left: 5px; padding-right: 0px"
                id="valor"
                placeholder="Valor da Renegociação"
                v-model="valorRenegociacao"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Parcelas" label-for="parcelas">
            <validation-provider #default="{ errors }" name="Parcelas">
              <b-form-input
                id="parcelas"
                :disabled="true"
                placeholder="Parcelas"
                v-model="antecipacao.limiteParcelasAntecipar"
                maxlength="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </section>
    <!-- Exoneração -->
    <section v-else-if="opcaoTipoAntecipacao == 'Exonerado'">
      <b-row>
        <b-col md="4">
          <validation-provider rules="required|zero" #default="{ errors }" name="Rescisão">
            <b-form-group label="Valor da Rescisão" label-for="valorRescisao">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input id="valorRescisao" placeholder="Valor da Rescisão" v-model="valorRescisao" v-number="number" />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="4">
          <b-form-group label="Valor da Renegociação" label-for="valor">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">R$</span>
              </b-input-group-prepend>
              <b-form-input
                :disabled="true"
                style="padding-left: 5px; padding-right: 0px"
                id="valor"
                placeholder="Valor da Renegociação"
                v-model="valorRenegociacao"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Parcelas" label-for="parcelas">
            <validation-provider #default="{ errors }" name="Parcelas">
              <b-form-input
                id="parcelas"
                :disabled="true"
                placeholder="Parcelas"
                v-model="antecipacao.quantidadeDeParcelas"
                maxlength="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="tw-flex tw-justify-items-end">
          <b-button variant="primary" @click="calcularValorRecisaoExonerados">Calcular</b-button>
        </b-col>
      </b-row>
    </section>
    <!-- Normal -->
    <section v-else="opcaoTipoAntecipacao == 'Normal'">
      <b-row>
        <b-col md="6">
          <b-form-group label="Total de Parcelas" label-for="totalParcelas">
            <b-form-input
              id="totalParcelas"
              readonly
              type="text"
              placeholder="Total de Parcelas"
              v-model="antecipacao.contratoSelecionado.numeroParcelas"
            />
          </b-form-group>
        </b-col>

        <!-- Valor das parcelas no momento -->
        <b-col md="6">
          <b-form-group label="Valor da Parcela" label-for="valorParcela">
            <b-form-input
              id="valorParcela"
              readonly
              type="text"
              placeholder="Valor da Parcela"
              v-model="antecipacao.contratoSelecionado.valorParcela"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Valor do contrato -->
        <b-col md="6">
          <b-form-group label="Valor do Contrato" label-for="valorDoContrato">
            <b-form-input
              id="valorDoContrato"
              readonly
              type="text"
              placeholder="Valor do Contrato"
              v-model="antecipacao.contratoSelecionado.valorContrato"
            />
          </b-form-group>
        </b-col>

        <!-- Quantidade de parcelas à antecipar -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Parcelas à Antecipar"
            v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/g, permiteAcentos: false }"
            :rules="{
              required: true,
              valor: Number(antecipacao.limiteParcelasAntecipar),
              zero: [],
            }"
          >
            <b-form-group label="Parcelas à Antecipar" label-for="parcelasAAntecipar">
              <b-form-input
                id="parcelasAAntecipar"
                type="text"
                placeholder="Parcelas à Antecipar"
                v-model="antecipacao.quantidadeDeParcelas"
              />
              <small class="text-primary">Máximo: {{ antecipacao.limiteParcelasAntecipar }} </small>
              <br />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
  import { formatarParaNumber, formatarValor } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import * as mathjs from 'mathjs'

  export default {
    components: {
      ValidationProvider,
      formatarValor,
    },
    props: {
      opcaoTipoAntecipacao: {
        type: String,
      },
      antecipacao: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        number: {},
        valorRescisao: formatarValor(0),
        valorRenegociacao: formatarValor(0),

        parcelasRenegociadas: [],
        quantidadeParcelasRenegociadas: 0,
      }
    },
    computed: {},
    mounted() {},
    methods: {
      calcularValorRecisaoExonerados() {
        // if (this.valorRescisao == '0,00') {
        //   this.$message.error('Erro: Valor da renegociação é obrigatório para o cálculo.')
        //   return
        // }
        // // Ordenando as parcelas em ordem decrescente de 'parcela'
        // const parcelasOrdenadas = this.antecipacao.contratoSelecionado.parcela.sort((a, b) => b.parcela - a.parcela)
        // let valorRestante = formatarParaNumber(this.valorRescisao)
        // const parcelasPagaveis = []
        // for (let parcela of parcelasOrdenadas) {
        //   if (valorRestante >= parcela.valor) {
        //     // Se o valor restante cobre o valor da parcela, adicionamos à lista
        //     parcelasPagaveis.push({ ...parcela, valorPago: parcela.valor })
        //     valorRestante -= parcela.valor
        //   } else if (valorRestante > 0) {
        //     // Pagamento parcial da última parcela possível
        //     parcelasPagaveis.push({ ...parcela, valorPago: valorRestante })
        //     valorRestante = 0 // Todo o valor disponível foi utilizado
        //     break // Encerramos o loop
        //   } else {
        //     // Não há mais valor disponível
        //     break
        //   }
        // }
        // this.valorRenegociacao = formatarValor(parcelasPagaveis.reduce((acc, cur) => acc + Number(cur.valorPago), 0))
        // this.antecipacao.quantidadeDeParcelas = parcelasPagaveis.length
        // this.antecipacao.contratoSelecionado.parcela = parcelasPagaveis
        // this.fieldsParcelas = [
        //   { key: 'parcela', label: 'Parcela' },
        //   { key: 'valorAntecipacao', label: 'Valor da Antecipação' },
        //   { key: 'valorPago', label: 'Valor Pago' },
        // ]
        // this.itemsParcelas = parcelasPagaveis
        // this.totalRowsParcelas = parcelasPagaveis.length
        // this.antecipacao.valorRenegociacao = this.valorRenegociacao

        if (!this.valorRescisao) {
          this.$message.error('Erro: Valor da renegociação é obrigatório para o cálculo.')
          return
        }

        let valorRestante = formatarParaNumber(this.valorRescisao)
        const parcelasPagaveis = []
        // Ordenando as parcelas em ordem decrescente de 'parcela'
        const parcelasOrdenadas = this.antecipacao.contratoSelecionado.parcela.sort((a, b) => b.parcela - a.parcela)
        for (let parcela of parcelasOrdenadas) {
          if (valorRestante >= parcela.valor) {
            // Se o valor restante cobre o valor da parcela, adicionamos à lista
            parcelasPagaveis.push({ ...parcela, valorPago: formatarParaNumber(parcela.valor) })
            valorRestante = Number(mathjs.subtract(mathjs.bignumber(valorRestante), mathjs.bignumber(parcela.valor)))
          } else if (valorRestante > 0) {
            // Pagamento parcial da última parcela possível
            parcelasPagaveis.push({ ...parcela, valorPago: formatarParaNumber(valorRestante) })
            valorRestante = 0 // Todo o valor disponível foi utilizado
            break // Encerramos o loop
          } else {
            // Não há mais valor disponível
            break
          }
        }

        this.calculou = true
        this.valorRenegociacao = formatarValor(parcelasPagaveis.reduce((acc, cur) => acc + cur.valorPago, 0))
        this.antecipacao.quantidadeDeParcelas = parcelasPagaveis.length
        this.antecipacao.valorRenegociacao = this.valorRenegociacao
        this.parcelasRenegociadas = parcelasPagaveis

        this.fieldsParcelas = [
          { key: 'parcela', label: 'Parcela' },
          {
            key: 'valorPago',
            label: 'Valor da Antecipação',
            formatter: (value) => {
              return value ? formatarValor(value) : formatarValor(0)
            },
          },
          {
            key: 'valor',
            label: 'Valor Anterior',
            formatter: (value) => {
              return value ? formatarValor(value) : formatarValor(0)
            },
          },
        ]
        this.itemsParcelas = parcelasPagaveis
        this.totalRowsParcelas = parcelasPagaveis.length

        const propsTable = { itemsParcelas: this.itemsParcelas, fieldsParcelas: this.fieldsParcelas }
        this.$emit('updateTableProps', propsTable)

        this.valorTotalParcelas = formatarValor(
          parcelasPagaveis.reduce(
            (accumulator, currentValue) => formatarParaNumber(accumulator) + formatarParaNumber(currentValue.valorPago),
            0,
          ),
        )
      },
      carregaObito() {
        this.valorRenegociacao = formatarValor(
          this.antecipacao.contratoSelecionado.parcela.reduce((acc, cur) => acc + formatarParaNumber(cur.valor), 0),
        )
        this.totalRowsParcelas = this.antecipacao.contratoSelecionado.parcela.length
        this.antecipacao.quantidadeDeParcelas = this.antecipacao.contratoSelecionado.parcela.length
        this.valorTotalParcelas = formatarValor(this.valorTotalParcelas)
        this.itemsParcelas = this.antecipacao.parcela
      },
    },
    watch: {
      opcaoTipoAntecipacao() {
        if (this.opcaoTipoAntecipacao == 'Falecido') this.carregaObito()
      },
    },
  }
</script>

<style></style>
b
