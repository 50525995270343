import { formatarData, formatarParaNumber } from '@/libs/utils'
import * as XLSX from 'xlsx'

const createSheet = (tableData: any[]) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de Antecipações')

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, 'relatorio-de-antecipacoes.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData, formatarParaNumber) => {
      return {
        Matricula: item.matricula ?? '',
        'Nome Completo': item.nomeCompleto ?? '',
        CPF: item.CPF ?? '',
        'Nº Contrato': item.codigoContrato ?? '',
        'Valor da Antecipação': item.valorAntecipacao ? formatarParaNumber(item.valorAntecipacao) : 0,
        'Valor Anterior': item.valorAnterior ? formatarParaNumber(item.valorAnterior) : 0,
        'Data Antecipação': item.dataAntecipacao ? formatarData(item.dataAntecipacao) : '',
        Parcelas: item.parcelas ?? '',
        Categorias: item.categorias ?? '',
        Telefone: item.telefone ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData, formatarParaNumber))
      createSheet(situationItems)
    },
  }
}
